/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Input, Form, Button, message } from 'antd'
import { gql, useMutation } from '@apollo/client'
import { useHistory } from 'react-router'
import { Logo } from '../components/Logo'
import { userStore } from '../stores/userStore'
import { UserTypeFragment } from '../model/UserType'
import { UserDto } from '../stores/userStore'
import { ErrorLogging } from '../Utils/ErrorLogging'
import _ from 'lodash'

const LoginMutation = gql`
  ${UserTypeFragment}
  mutation LoginMutation($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      user {
        id
        firstname
        lastname
        username
        type {
          ...UserTypeFragment
        }
      }
      token
    }
  }
`

const MainContainer = styled.div`
  width: 672px;
  height: 672px;
  background-color: #f1f7ff;
`

const InputStyled = styled(Input)`
  width: 350px;
  height: 56px;
  padding: 16px 24px;
  /* margin-bottom: 16px; */
  font-size: 18px;
  line-height: 23.4px;
  border: 1px solid #ebecee;
  border-radius: 8px;
  :focus {
    outline: none;
    border: 1px solid #e4e4e4;
  }
  ::placeholder {
    color: #bac0cb;
  }
`

const label = css`
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  margin-bottom: 12px;
`

type InputItemProps = {
  name: string
  [key: string]: any
}

const ButtonStyled = styled(Button)`
  font-family: Kanit;
  font-size: 18px;
  padding: 15px 0;
  width: 350px;
  height: 57px;
  color: white;
  cursor: pointer;
  background-color: #1890ff;
  border-radius: 4px;
  margin: 12px 0;
  :hover {
    background-color: #0a7eeb;
  }
`

const InputItem = ({ name, ...rest }: InputItemProps) => {
  return (
    <>
      <div css={label}>{name}</div>
      <InputStyled id={name} name={name} {...rest} />
    </>
  )
}

export const LoginPage = () => {
  const history = useHistory()
  const { setMe, setToken } = userStore
  const [login, { loading, error, data }] = useMutation(LoginMutation, {
    onCompleted: (data) => {
      if (data.login) {
        message.success('Login success!')
        setToken(data.login?.token)
        setMe(data.login?.user)
        const user: UserDto = data.login?.user
        if (_.includes(['OWNER', 'SALE', 'ZONE', 'MECHANIC', 'ACCOUNTING'], user.type.key)) {
          history.push(`/${user.type.key.toLowerCase()}`);
        } else {
          message.error('User type error!')
        }
      }
    },
  })
  const [form] = Form.useForm()
  const onFinish = async (variables: any) => {
    const res = await login({
      variables,
    })
    if (res.errors) {
      console.log("onError", res.errors)
      console.log('err =:', res.errors[0].message)
      message.error(res.errors[0].message)
      ErrorLogging.log('LoginPageLoginMutation', res.errors)
    }
  }
  return (
    <MainContainer className="m-auto flex justify-center">
      <div className="m-auto flex justify-center flex-col align-middle items-center">
        <>
          <Logo />
          <Form form={form} onFinish={onFinish}>
            <Form.Item
              name="username"
              rules={[{ required: true, message: 'กรุณากรอก username!' }]}
              css={css`
                margin-bottom: 16px;
              `}
            >
              <InputItem name="username" placeholder="Username" type="string" />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: 'กรุณากรอก password!' }]}
              css={css`
                margin-bottom: 16px;
              `}
            >
              <InputItem
                name="password"
                placeholder="Password"
                type="password"
              />
            </Form.Item>
            <Form.Item>
              <ButtonStyled
                loading={loading}
                type="primary"
                htmlType="submit"
                className="w-full flex justify-center py-2 px-4 border-transparent"
              >
                เข้าสู่ระบบ
              </ButtonStyled>
            </Form.Item>
          </Form>
        </>
      </div>
    </MainContainer>
  )
}
