import {
  action,
  computed,
  observable,
  observe,
  makeAutoObservable,
} from "mobx";
import { UserType } from "./userTypeStore";

export type UserDto = {
  id?: string;
  firstname: string;
  lastname: string;
  username: string;
  zones?: string[];
  type: UserType;
  lastModified?: Date;
  lastModifidDate?: Date;
  image?: string;
  profile?: {
    src?: string
  }
  modifyById?: string;
  modifyBy?: ModifyByDto
};
export type ModifyByDto = {
  id: string;
  firstname: string;
  lastname: string;
};
export enum Role {
  OWNER = 'OWNER',
  SALES = 'SALE',
  ZONER = 'ZONE',
  MECHANIC = 'MECHANIC',
  ACCOUNTING = 'ACCOUNTING'
}

class UserStore {
  constructor() {
    this.load();
    makeAutoObservable(this);
    observe(this, (change) => {
      if (change.name === "token") {
        this.save();
      }
    });
  }

  @observable
  token?: string;

  @observable
  me?: UserDto;

  @action
  load = () => {
    if (typeof localStorage !== "undefined") {
      const token = localStorage.getItem("token");
      if (token) {
        this.token = token;
      }
    }
  };

  private save = () => {
    if (typeof localStorage !== "undefined" && this.token) {
      localStorage.setItem("token", this.token);
    }
  };

  @action
  setMe = (me: UserDto) => {
    this.me = me;
  };

  @action
  setToken = (token: string) => {
    this.token = token;
  };

  @computed
  get isLoggedIn(): boolean {
    return !!this.token;
  }
}

export const userStore = new UserStore();
