/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const logoImg = css`
  width: 230px;
  margin-bottom: 37px;
  max-width: 100%;
`;

export const Logo = () => {
  return (
    <img
      src={process.env.PUBLIC_URL + "/assets/golf_city_logo.png"}
      alt="GOLF CITY"
      css={logoImg}
    />
  );
};
