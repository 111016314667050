import './index.css'
import 'antd/dist/antd.css'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'

import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import App from './App'
import reportWebVitals from './reportWebVitals'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { ErrorLogging } from './Utils/ErrorLogging'

Sentry.init({
  dsn: 'https://e416d8c22252457d839466819c3e6b1c@o841849.ingest.sentry.io/6248903',
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_API_URL ? 'production' : 'development',
  normalizeDepth: 10,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

const errorLoggingLink = onError(
  ({ graphQLErrors, networkError, operation, response }) => {
    if (
      ((graphQLErrors && graphQLErrors.length > 0) || networkError) &&
      operation.operationName
    ) {
      ErrorLogging.log(`ApolloError:${operation.operationName}`, {
        operation: operation.operationName,
        variable: operation.variables,
        networkError: networkError ? JSON.stringify(networkError) : '',
        graphQLErrors: JSON.stringify(graphQLErrors),
        query: operation?.query?.loc?.source?.body,
      })
    }
  }
)

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_URL || 'http://localhost:8080'}/graphql`,
})

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token')
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const client = new ApolloClient({
  link: ApolloLink.from([authLink, errorLoggingLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
})

ReactDOM.render(
  <Router>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </Router>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
