import { gql } from "@apollo/client";
import { UserTypeFragment } from "./UserType";

export const SiteConfigFragment = gql`
  ${UserTypeFragment}
  fragment SiteConfigFragment on SiteConfigType {
    userTypes {
      ...UserTypeFragment
      __typename
    }
  }
`;
