

import React, { ReactElement, useEffect, useState } from "react";
import { useSystemSetting } from "../hooks/useSystemSetting";


const useInternalHook = () => {

 const [customDate, setCustomDate] = useState<number|undefined>(undefined)
 const [id, setId] = useState<string|undefined>(undefined)
 const u = useSystemSetting()

 const getSystemSetting = async () => {
  const res = await u.getSystemSetting()
  if (res) {
    setCustomDate(res.SystemSetting?.customDate)
    setId(res.SystemSetting?.id)
  }
 }
 useEffect(() => {
   getSystemSetting()
 }, [])
 
const onUpdateSystemSetting = async () => {


     await  u.updateSystemSetting() 
     await  getSystemSetting()
  

}
  return {
    customDate,
    setCustomDate,
    onUpdateSystemSetting,
    u,
    systemData:u.systemData,
    setSystemData:u.setSystemData
  };
};

export const MainContext = React.createContext<
  ReturnType<typeof useInternalHook> | undefined
>(undefined);

export const useMainContext = () => {
  const c = React.useContext(MainContext);
  if (!c) {
    throw "MainContext";
  }
  return c;
};

export const MainProvider = (p: { children: ReactElement }) => {
  const h = useInternalHook();
  return (
    <MainContext.Provider value={h}>
      {p.children}
    </MainContext.Provider>
  );
};
