import { gql, useQuery } from "@apollo/client";
import { SiteConfigFragment } from "../model/SiteConfig";
import { ConfigType, configStore } from "../stores/configStore";

const SiteConfigQuery = gql`
  ${SiteConfigFragment}
  {
    config {
      ...SiteConfigFragment
    }
  }
`;

export const useSiteConfig = () => {
  const { setUserTypes } = configStore;
  const { loading, error, data, refetch } = useQuery<{ config?: ConfigType }>(
    SiteConfigQuery,
    {
      onCompleted: (data) => {
        setUserTypes(data?.config?.userTypes??[]);
      },
    }
  );

  return [
    data?.config,
    {
      loading,
      error,
      refetch,
    },
  ];
};
