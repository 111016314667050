import { useApolloClient } from "@apollo/react-hooks";
import { useEffect, useState } from "react";
import { SystemSettingDocument, SystemSettingQuery, SystemSettingQueryVariables, UpdateSystemSettingDocument, UpdateSystemSettingMutation, UpdateSystemSettingMutationVariables } from "../gql/generated/graphql";

export const useSystemSetting = () => {
  const client = useApolloClient();
  const [loading, setLoading] = useState<boolean>(false);

  const [value, setValue] = useState<SystemSettingQuery | undefined | null>();

  const [customDate, setCustomDate] = useState<number | undefined>(undefined);
  useEffect(() => {
    getSystemSetting()
  }, [])
  
  const getSystemSetting :() => Promise<SystemSettingQuery | undefined | null> = async () => {
    setLoading(true);
    try {
      const income = await client.query<SystemSettingQuery,SystemSettingQueryVariables>({
        query: SystemSettingDocument,
        variables:{}
      })
      if (income.errors) {
        throw income.errors;
      }
      setLoading(false);
      setValue(income?.data)

      if (income?.data) {
        console.log("setCustomDate",income?.data.SystemSetting?.customDate)
        setCustomDate(income?.data.SystemSetting?.customDate)
      }
      return income?.data
    } catch (error) {
      console.error(error)
    }
  }
  const updateSystemSetting:() => Promise<UpdateSystemSettingMutation | undefined | null> = async () => {
    setLoading(true);
    if (value?.SystemSetting) {

      console.log(value?.SystemSetting);
    try {
        const income = await client.mutate<UpdateSystemSettingMutation,UpdateSystemSettingMutationVariables>({
          mutation: UpdateSystemSettingDocument,
          variables:{
           data:{customDate:value?.SystemSetting.customDate,id:value?.SystemSetting.id}
          }
        })
 
      if (income.errors) {
        throw income.errors;
      }
      setLoading(false);
      return income?.data
    } catch (error) {
      console.error(error)
    }
  }
  }

  return {
    loading,
    getSystemSetting,
    updateSystemSetting,
    systemData:value,
    setSystemData:setValue,
    customDate
  };
};