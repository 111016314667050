import { LoginPage } from "./Login";
import { Switch, Route } from "react-router-dom";
import { useMe } from "./hooks/useMe";
import { useHistory, useLocation } from "react-router";
import { message } from "antd";
import { useEffect, lazy, Suspense } from "react";
import { useSiteConfig } from "./hooks/useSiteConfig";
import queryString from "query-string";
import { MainProvider } from "./providers/Main.provider";
import _ from "lodash";

const OwnerContainer = lazy(() => import("./Owner/Main/Container"));
const ZoneContainer = lazy(() => import("./Zone/Main/Container"));
const MechanicContainer = lazy(() => import("./Mechanic/Main/Container"));
const SaleContainer = lazy(() => import("./Sale/Main/Container"));
const AccountingContainer = lazy(() => import("./Accounting/Main/Container"));
const SystemSettingContainer = lazy(() => import("./SystemSetting/Main/Container"));
const App = () => {
  useSiteConfig();
  const history = useHistory();
  const location = useLocation();
  const [me, { loading }] = useMe();

  useEffect(() => {
    if (!me) {
      history.replace(`/login?redirect=${location.pathname}`);
    } else {
      const qs = queryString.parse(location.search);
      if (me) {
        if (typeof qs.redirect === "string" && qs.redirect !== "/") {
          history.push(qs.redirect);
        } else if (_.includes(['OWNER', 'SALE', 'ZONE', 'MECHANIC', 'ACCOUNTING'], me.type.key)) {
          history.push(`/${me.type.key.toLowerCase()}`);
        } else {
          message.error("User type error!");
        }
      }
    }
  }, [me]);
  if (loading) {
    return <div>loading...</div>;
  }
  return (
    <div className="body">
      <Suspense fallback={<div>loading...</div>}>
        <MainProvider>
          <Switch>
            <Route exact path="/login" component={LoginPage} />
            <Route path="/owner" component={OwnerContainer} />
            <Route path="/zone" component={ZoneContainer} />
            <Route path="/mechanic" component={MechanicContainer} />
            <Route path="/sale" component={SaleContainer} />
            <Route path="/accounting" component={AccountingContainer} />
            <Route path="/systemSetting" component={SystemSettingContainer} />
          </Switch>
        </MainProvider>
      </Suspense>
    </div>
  );
};

export default App;
