import { action, observable } from "mobx";
import { UserType } from "./userTypeStore";

export type ConfigType = {
  userTypes: UserType[];
};

class ConfigStore {
  @observable
  userTypes?: UserType[];

  @action
  setUserTypes = (userTypes: UserType[]) => {
    this.userTypes = [...userTypes];
  };
}

export const configStore = new ConfigStore();
