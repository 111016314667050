import { gql, useQuery } from "@apollo/client";
import { QueryHookOptions } from "@apollo/client/react/types/types";
import { OperationVariables } from "@apollo/client/core";
import { userStore } from "../stores/userStore";
import { UserTypeFragment } from "../model/UserType";

const MeQuery = gql`
  ${UserTypeFragment}
  {
    me {
      id
      firstname
      lastname
      username
      image
      profile {
        src
      }
      type {
        ...UserTypeFragment
      }
      zoneOf {
        id
        title
      }
      typeKey
    }
  }
`;

export const useMe = <TVariables = OperationVariables>(
  options?: QueryHookOptions<any, TVariables>
) => {
  const { setMe } = userStore;
  const _onCompleted = options?.onCompleted;
  const { loading, error, data, refetch } = useQuery(MeQuery, {
    ...options,
    onCompleted: (data) => {
      setMe(data.me);
      if (_onCompleted) {
        _onCompleted(data);
      }
    },
  });
  const me = data?.me;
  return [
    me,
    {
      loading,
      error,
      data,
      refetch,
    },
  ];
};
