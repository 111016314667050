import { gql } from "@apollo/client";

export const UserTypeFragment = gql`
  fragment UserTypeFragment on ConfigUserType {
    id
    namespaceTh
    namespaceEn
    key
    __typename
  }
`;
